#accountNav,
#topNav,
#topNavPlaceholder,
.snFooterContainer {
  display: none;
}

body {
  .has-mobile-nav & {
    position: static !important;
  }
}

.site-background {
  background-size: cover;
  top: 0 !important;
  height: 100vh;

  .has-mobile-nav & {
    background-size: cover;
    background-position: center;
  }
}

.siteContainer {
  padding-top: 0 !important;
}

#panelTwo:before {
  background: transparent;
  box-shadow: none;
}

.layoutContainer {
  background: transparent;
}

#displayBodyHeader {
  display: none;
}

// Custom Classes start here

.has-mobile-nav .site-background {
  display: none;
}

.user_mode #panelTwo {
  font-family: 'Montserrat', sans-serif;
  padding-top: 10px;
  padding-bottom: 10px;

  .text {
    color: white;
    line-height: 1.3;
    text-shadow: 2.571px 3.064px 7px rgba(0, 0, 0, 0.3);

    a {
      color: white;
      text-decoration: underline;

      &:hover {
        color: #a29062;
      }
    }
  }

}

.splash-header.layoutContainer {
  max-width: 727px;
}

.splash-buttons.layoutContainer {
  max-width: 755px;

  img {
    max-height: 210px;
    width: auto;
    transition: transform .2s linear;

    &:hover {
      transform: translateY(-10px);
    }
  }

}

.splash-footer.layoutContainer {
  padding-top: 50px;

  .text {
    text-transform: uppercase;
  }
}

@media only screen and (min-width: 1024px) {
  .site-background {
    top: 0 !important;
  }
}